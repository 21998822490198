<template>
  <v-stepper
    v-model="currentStepId"
    vertical
  >
  <v-container fluid>
  <v-alert v-if="cupoMSG || !cupoDisponible && gradoEscolarSl.length > 0"
      border="top"
      color="red lighten-2"
      dark
      data-alert="test"
    >
      {{ msgModal }}
    </v-alert>
    <v-alert dense type="info" v-show="centro === 'PCIS'">
      <p class="subtitle-2" v-if="this.$i18n.locale === 'es'">
      Actualmente contamos con poca disponibilidad de cupos en algunos grados,
      por lo que al aplicar al Puntacana International School no garantizamos que al momento
      de hacer su solicitud tendremos cupos disponibles, en la entrevista inicial o vía correo
      electrónico les estaremos informando la disponibilidad o si se estarían
      incluyendo en la lista de espera para el curso que aplica.</p>
      <p class="subtitle-2" v-if="this.$i18n.locale === 'es'">
      De usted continuar con el proceso, aun después de informarle de la no
      disponibilidad, de aperturar cupo/s se les estará dando prioridad a las familias que
      hayan completado la entrevista inicial y evaluación.</p>
      <p class="subtitle-2" v-if="this.$i18n.locale === 'en'">
      We currently have limited availability in some grades,
      Therefore, when applying to Puntacana International School we do not guarantee
      that we will have spaces available at the time of we do not guarantee that when
      you apply to Puntacana International School we will have places available.
      we will be informing you of the availability or if you will be included in the waiting
      list for the be included in the waiting list for the course you are applying for.</p>
      <p class="subtitle-2" v-if="this.$i18n.locale === 'en'">
        If you continue with the process, even after informing you of the non-availability
        of a space, you will be given priority availability, priority will be given to families
        who have completed the initial interview and assessment. completed the initial
        interview and evaluation.
      </p>
    </v-alert>
    <v-alert type="error" :value="alert_grado">
      {{ msg_grado }}
    </v-alert>
    </v-container>
    <v-stepper-step
      :complete="currentStepId > 1"
      :step="1"
      editable
      :rules="[() => currentStepId == 1?!!valid:true]"
      edit-icon="$complete"
    >
      {{ $t('Informaciones Generales - Paso') }} 1
    </v-stepper-step>

    <v-stepper-content step="1">
       <v-form ref="form1" v-model="valid" lazy-validation>
        <v-container fluid>
        <v-row>
          <v-col
                cols="12"
                md="4">
                <v-select
                    v-model="centro"
                    :error-messages="centroErrors"
                    :items="centroOptions"
                    :label="$t('Centro')+':'"
                    outlined
                    required
                    @input="$v.centro.$touch()"
                    @blur="$v.centro.$touch()"
                    ></v-select>
            </v-col>
          <v-col
                cols="12"
                md="4">
                <v-select
                    v-model="annioEscolarSl"
                    :disabled="!periodoptions && !centro"
                    :items="periodoptions?periodoptions: [{text: '', value: 0}]"
                    :label="$t('Año Escolar')+':'"
                    outlined
                    :rules="[rules.required]"
                    ></v-select>
            </v-col>
          <v-col
                cols="12"
                md="4">
               <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateFormatted"
                    :label="$t('Fecha de Nacimiento')+':'"
                    outlined
                    prepend-icon="mdi-calendar"
                    readonly
                    @change="fecha_nacimiento = parseDate(dateFormatted)"
                    :rules="[rules.required]"
                    v-bind="attrs"
                    v-on="on"
                    :disabled="!annioEscolarSl"
                  ></v-text-field>
                </template>
                <v-date-picker
                 v-model="fecha_nacimiento"
                 locale="es-es"
                :active-picker.sync="activePicker"
                :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000))
                .toISOString().substr(0, 10)"
                min="1990-01-01"
                @change="save"
              ></v-date-picker>
              </v-menu>
            </v-col>
          <v-col
                cols="12"
                md="4">
                <v-autocomplete
                    v-model="gradoEscolarSl"
                    :disabled="!fecha_nacimiento"
                    :items="gradoptions?gradoptions: [{text: '', value: 0}]"
                    :label="$t('Grado Escolar')+':'"
                    outlined
                    :rules="[rules.required]"
                    ></v-autocomplete>
            </v-col>
          <template v-if="cupoDisponible">
          <v-col
                cols="12"
                md="4">
                <v-select
                    v-model="tutor"
                    :disabled="!gradoEscolarSl"
                    :items="tutorOptions"
                    :label="$t('Tutor Principal')+':'"
                    outlined
                    :rules="[rules.required]"
                    ></v-select>
            </v-col>
            <v-col
                cols="12"
                md="4">
                <v-select
                    v-model="estudiante_vive"
                    :items="viveOptions"
                    :disabled="!tutor"
                    :label="$t('El estudiante vive con')+':'"
                    outlined
                    :rules="[rules.required]"
                    ></v-select>
            </v-col>
            </template>
            <v-col
                v-if="this.datosfrm && tutor == 'otros' ||
                this.datosfrm && inputData.VIVECON == 'otros'"
                cols="12"
                md="4">
                <v-text-field
                    v-model="tutorOtro"
                    label="Especifique Parentesco:"
                    outlined
                    :rules="[rules.required]"
                ></v-text-field>
                </v-col>
        </v-row>
        <template v-if="this.datosfrm && tutor == 'otros' && tutorOtro.length>0
          || inputData.VIVECON == 'otros' && inputData.VIVECON.length>0">
        <v-row>
            <v-col>
                <v-card-title class="text-h5 blue lighten-5">
                    Generales para {{ tutorOtro }}:
                </v-card-title>
            </v-col>
        </v-row>
        <v-row>
          <FrmTutor tipo="tutorOtro"  ref="otrosfrm" />
        </v-row>
        </template>
        </v-container>
      <v-btn
        color="primary"
        :disabled="cupoDisponible === false || !valid"
        @click="handleValidateTutor('start')"
      >
        {{ $t('Continuar')}}
      </v-btn>
        </v-form>
    </v-stepper-content>
    <template v-if="estudiante_vive !== ''">
    <template v-if="this.datosfrm && tutor && tutor !== 'madredvc'">
    <v-stepper-step
      step="2"
      :complete="currentStepId > 2"
      :editable="currentStepId > 1"
      :rules="[() => currentStepId == 2?!!valid:true]"
      edit-icon="$complete"
    >
     {{ $t('Generales Padre - Paso') }} 2
    </v-stepper-step>

    <v-stepper-content step="2">
      <v-card
        class="mb-12"
      >
        <FrmTutor tipo="padre"  ref="padrefrm" />
      </v-card>
      <v-btn
        color="primary"
        @click="handleValidateTutor('padrefrm')"
      >
        {{ $t('Continuar')}}
      </v-btn>
      <v-btn text @click="currentStepId -= 1; valid = true">
        {{ $t('Atras')}}
        </v-btn>
    </v-stepper-content>
    </template>

    <template v-if="this.datosfrm && tutor && tutor !== 'padredvc'">
    <v-stepper-step
      v-if="this.datosfrm && tutor"
      :step="tutor === 'madredvc'? 2 : 3"
      :complete="currentStepId > 3"
      :editable="currentStepId > 2"
      :rules="[() => currentStepId == 3?!!valid:true]"
      edit-icon="$complete"
    >
     {{$t('Generales Madre - Paso')}} {{ tutor === 'madredvc'? 2 : 3 }}
    </v-stepper-step>

    <v-stepper-content
        v-if="this.datosfrm && tutor"
        :step="tutor === 'madredvc'? 2 : 3">
      <v-card
        class="mb-12"
      >
      <FrmTutor tipo="madre"  ref="madrefrm" />
      </v-card>
      <v-btn
        color="primary"
        @click="handleValidateTutor('madrefrm')"
      >
        {{ $t('Continuar')}}
      </v-btn>
      <v-btn text @click="currentStepId -= 1; valid = true">
        {{ $t('Atras')}}
        </v-btn>
    </v-stepper-content>
    </template>

    <v-stepper-step
      v-if="this.datosfrm && tutor"
      :step="tutor === 'padredvc' || tutor === 'madredvc'? 3 : 4"
      :complete="currentStepId > 4"
      :editable="currentStepId > 3"
      :rules="[() => currentStepId == 4?!!valid:true]"
      edit-icon="$complete"
    >
    {{$t('Información Estudiante - Paso')}}
    {{ tutor === 'padredvc' || tutor === 'madredvc'? 3 : 4 }}
    </v-stepper-step>

    <v-stepper-content
        v-if="this.datosfrm && tutor"
        :step="tutor === 'padredvc' || tutor === 'madredvc'? 3 : 4">
      <v-card
        class="mb-12"
      >
      <FrmEstudiante  :gradoEscolarSl="gradoEscolarSl" ref="estudianteFrm"/>
      </v-card>
      <v-btn
        color="primary"
        @click="handleValidateTutor('estudianteFrm')"
      >
        {{ $t('Continuar')}}
      </v-btn>
      <v-btn text @click="currentStepId -= 1; valid = true">
        {{ $t('Atras')}}
        </v-btn>
    </v-stepper-content>

    <v-stepper-step
      v-if="this.datosfrm && tutor"
      :step="tutor === 'padredvc' || tutor === 'madredvc'? 4 : 5"
      :complete="currentStepId > 5"
      :editable="currentStepId > 4"
      :rules="[() => currentStepId == 5?!!valid:true]"
      edit-icon="$complete"
    >
     {{$t('Información del desarrollo - Paso')}}
    {{ tutor === 'padredvc' || tutor === 'madredvc'? 4 : 5 }}
    </v-stepper-step>

    <v-stepper-content
        v-if="this.datosfrm && tutor"
        :step="tutor === 'padredvc' || tutor === 'madredvc'? 4 : 5">
      <v-card
        class="mb-12"
      >
      <InformacionDesarrollo ref="desarrolloFrm"/>
      </v-card>
      <v-btn
        color="primary"
        @click="handleValidateTutor('desarrolloFrm')"
      >
        {{ $t('Continuar')}}
      </v-btn>
      <v-btn text @click="currentStepId -= 1; valid = true">
        {{ $t('Atras')}}
        </v-btn>
    </v-stepper-content>

    <v-stepper-step
      v-if="this.datosfrm && tutor"
      :step="tutor === 'padredvc' || tutor === 'madredvc'? 5 : 6"
      :complete="currentStepId > 6"
      :editable="currentStepId > 5"
      :rules="[() => currentStepId == 6?!!valid:true]"
      edit-icon="$complete"
    >
     {{$t('Informaciones Médicas / Otras - Paso')}}
      {{ tutor === 'padredvc' || tutor === 'madredvc'? 5 : 6 }}
    </v-stepper-step>

    <v-stepper-content
        v-if="this.datosfrm && tutor"
        :step="tutor === 'padredvc' || tutor === 'madredvc'? 5 : 6">
      <v-card
        class="mb-12"
      >
      <FrmOtras  ref="otrasFrm"/>
      </v-card>
      <v-btn color="success" @click.prevent.stop="handleValidateTutor('otrasFrm')">
        {{ $t('Enviar')}}
      </v-btn>
      <v-btn text @click="currentStepId -= 1; valid = true">
        {{ $t('Atras')}}
        </v-btn>
    </v-stepper-content>
</template>
<v-dialog
      v-model="loading"
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Cargando...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <template>
  <v-row justify="center">
    <v-dialog
      v-model="showModal"
      persistent
      max-width="300"
    >
      <v-card>
        <v-card-title class="text-h5">
          {{$t('Mensaje')}}
        </v-card-title>
        <v-card-text>
            {{ msgModal }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="showModal = false"
          >
            {{$t('Cerrar')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
  </v-stepper>
</template>
<script>
import axios from 'axios';
import { required } from 'vuelidate/lib/validators';
import { mapMutations, mapActions, mapGetters } from 'vuex';
import FrmTutor from './formulario/DatosTutor.vue';
import FrmEstudiante from './formulario/DatosEstudiante.vue';
import InformacionDesarrollo from './formulario/InformacionDesarrollo.vue';
import FrmOtras from './formulario/OtrasInformaciones.vue';

const STEPS = [
  {
    id: 1, name: 'Profile*', rules: [(v) => !!v || 'Required.'], isEditing: true, valid: true,
  },
  {
    id: 2, name: 'All my<br/> requests*', rules: [(v) => !!v || 'Required.'], valid: true,
  },
];

export default {
  name: 'Admission',
  validations: {
    centro: { required },
  },
  components: {
    FrmTutor,
    FrmEstudiante,
    FrmOtras,
    InformacionDesarrollo,
  },
  data() {
    return {
      loading: false,
      valid: true,
      alert_grado: false,
      msg_grado: '',
      cupoDisponible: true,
      cupoMSG: false,
      currentStepId: 1,
      steps: STEPS,
      showModal: false,
      menu: false,
      activePicker: null,
      dateFormatted: '',
      fecha_nacimiento: null,
      msgModal: '',
      fechaActual: new Date().toISOString().split('T')[0].replaceAll('-', ''),
      idsolcupo: (this.$route.params.id) ? this.$route.params.id : 0,
      inputData: [],
      padre: null,
      madre: null,
      tutorDatos: null,
      estudiante_vive: '',
      tutor: '',
      tutorOtro: '',
      centro: '',
      centroOptions: [],
      annioEscolarSl: '',
      periodoptions: false,
      gradoEscolarSl: false,
      gradoEscolarSlNom: false,
      gradoptions: false,
      annioEscolar: [],
      viveOptionsValues: [
        { text: 'Ambos padres', value: 'padres' },
        { text: 'Madre', value: 'madre' },
        { text: 'Padre', value: 'padre' },
        { text: 'Otros', value: 'otros' },
      ],
      // tutorOptions: [
      //   { text: this.$t('Madre'), value: 'madre' },
      //   { text: 'Padre', value: 'padre' },
      //   { text: 'Madre Divorciada', value: 'madredda' },
      //   { text: 'Madre Soltera', value: 'madredvc' },
      //   { text: 'Madre Viuda', value: 'madredvc' },
      //   { text: 'Padre Divorciado', value: 'padreddo' },
      //   { text: 'Padre Soltero', value: 'padredvc' },
      //   { text: 'Padre Viudo', value: 'padredvc' },
      //   { text: 'Otros', value: 'otros' },
      // ],
      tipoIdOptions: [
        { text: 'Cédula', value: 1 },
        { text: 'Pasaporte', value: 2 },
      ],
      emailRules: [
        (v) => !!v || this.$t('login.validateFrm.email'),
        (v) => /.+@.+\..+/.test(v) || this.$t('login.validateFrm.email_valid'),
      ],
      rules: {
        required: (value) => !!value || this.$t('login.validateFrm.required'),
        min: (v) => v?.length >= 8 || this.$t('login.validateFrm.min'),
      },
    };
  },
  watch: {
    menu(val) {
      // eslint-disable-next-line no-return-assign
      return val && setTimeout(() => (this.activePicker = 'YEAR'));
    },
    fecha_nacimiento() {
      this.dateFormatted = this.formatDate(this.fecha_nacimiento);
      // Eliminar grado si ya esta seleccionado al cambiar la fecha
      this.gradoEscolarSl = false;
    },
    valid(value) {
      if (!value && this.currentStepId > 1) {
        this.msgModal = this.$t('Faltan Campos');
        this.showModal = true;
      }
    },
    tutor(value) {
      this.viveOptions = [
        { text: 'Ambos padres', value: 'padres' },
        { text: 'Madre', value: 'madre' },
        { text: 'Padre', value: 'padre' },
        { text: 'Otros', value: 'otros' },
      ];

      switch (value) {
        case 'madredda':
          this.viveOptions = this.viveOptions.filter((el) => el.value !== 'padres');
          break;
        case 'padreddo':
          this.viveOptions = this.viveOptions.filter((el) => el.value !== 'padres');
          break;
        case 'madredvc':
          this.viveOptions = this.viveOptions.filter((el) => el.value !== 'padres').filter((el) => el.value !== 'padre');
          break;
        case 'padredvc':
          this.viveOptions = this.viveOptions.filter((el) => el.value !== 'padres').filter((el) => el.value !== 'madre');
          break;
        case 'madresoltera':
          this.viveOptions = this.viveOptions.filter((el) => el.value !== 'padres').filter((el) => el.value !== 'padre');
          //   console.log(value);
          // this.viveOptions = madreSoltera;
          break;
        case 'padresoltero':
          this.viveOptions = this.viveOptions.filter((el) => el.value !== 'padres').filter((el) => el.value !== 'madre');
          break;
        case 'otros':
          this.viveOptions = this.viveOptions.filter((el) => el.value === 'otros');
          this.inputData.VIVECON = 'otros';
          break;
        default:
      }
      // this.inputData.TUTOR = this.tutor;
      this.tutor_principal(this.tutor);
      //   console.log(this.viveOptions);

      if (value !== 'otros') {
        this.tutorOtro = '';
      }
    },
    async centro(value) {
      if (value.length > 0) {
        // Limpiar Campos
        this.annioEscolarSl = '';
        this.gradoEscolarSl = false;
        this.alert_grado = false;
        this.valid = false;
        this.loading = true;
        try {
          const { data } = await axios.get(`api/formadmission/centro/?centro=${value}`);
          this.annioEscolar = data;

          const centro = [...new Set(data.map((item) => item.ANNOESC))];
          //   console.log(centro);

          this.periodoptions = centro.map((val) => ({
            text: val, value: val,
          }));
        } catch (error) {
          // console.log(error.response);
          const { msg } = error.response.data;
          this.periodoptions = false;
          this.msg_grado = msg.replace(/\bI::000 &|&$/ig, '');
          this.alert_grado = true;
        }

        this.loading = false;
      }
    },
    annioEscolarSl(value) {
      // this.makeOptionsGrade([{
      //   text: 'prueba', value: 1,
      // }]);

      // console.log(this.annioEscolar);
      // debugger;

      // return;

      // eslint-disable-next-line no-unreachable
      if (value.length > 0) {
        const key = 'GRADOESC';

        const grado = [...new Map(this.annioEscolar.map(
          (item) => [item[key], item],
        )).values()];

        grado.sort((a, b) => {
          const nameA = parseInt(a.GRADOESC, 10); // ignore upper and lowercase
          const nameB = parseInt(b.GRADOESC, 10); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          // names must be equal
          return 0;
        });

        // eslint-disable-next-line no-unreachable
        this.gradoptions = grado.map((val) => ({
          text: val.DESCRIPCION, value: val.GRADOESC,
        }));
      }
    },
    gradoEscolarSl(value) {
      if (value && value.length > 0 && this.annioEscolar.length > 0) {
        try {
          const gradoSelecionado = this.annioEscolar.filter(
            (val, index, arr) => arr[index].GRADOESC === value,
          );

          this.gradoEscolarSlNom = `( ${gradoSelecionado[0].DESCRIPCION} )`;
          // console.log(gradoSelecionado[0].DESCRIPCION);

          const {
            CUPO,
            CUPO_ADICIONAL,
            LISTA_ESPERA,
            OCUPADO,
            PROCESO,
          } = gradoSelecionado[0];

          const totalCuposDisponible = (CUPO + CUPO_ADICIONAL) - OCUPADO - LISTA_ESPERA - PROCESO;
          // const totalCuposDisponible = (CUPO + CUPO_ADICIONAL) - 30;
          // console.log({ totalCuposDisponible });
          // eslint-disable-next-line no-constant-condition
          if (totalCuposDisponible <= 0) {
            // this.msgModal = `No hay cupos disponibles para el grado
            //   ${this.gradoEscolarSlNom} seleccionado - Puede continuar con la
            //   solicitud, sera asignada a lista de espera.`;
            // this.cupoDisponible = true;
            // this.cupoMSG = true;
          } else {
            // Validar Grado Toddler I (TI)
            /* if (this.gradoEscolarSl === 'T1') {
              const checkGrade = this.fvalidGrade(
              `${new Date().getFullYear()}-08-31`, this.fecha_nacimiento, 2);
              console.log({ checkGrade });
              if (checkGrade) {
                this.cupoDisponible = true;
                this.msgModal = '';
                console.log(`${this.gradoEscolarSlNom} edad valida`);
              } else {
                this.msgModal = `El estudiante no cumple con la edad requerida para la solicitud
                                de inscripción al grado ${this.gradoEscolarSlNom} seleccionado.`;
                this.cupoDisponible = false;
                console.log('edad no valida');
              }
            } */

            switch (this.gradoEscolarSl) {
              case 'T1': {
                const checkGrade = this.fvalidGrade(`${new Date().getFullYear()}-08-31`, this.fecha_nacimiento, 2);
                if (!checkGrade) {
                  this.msgModal = `El estudiante no cumple con la edad requerida para la solicitud
                                de inscripción al grado ${this.gradoEscolarSlNom} seleccionado.`;
                  this.cupoDisponible = false;
                }
                break;
              }
              case 'T2': {
                const checkGrade = this.fvalidGrade(`${new Date().getFullYear()}-08-31`, this.fecha_nacimiento, 3);
                if (!checkGrade) {
                  this.msgModal = `El estudiante no cumple con la edad requerida para la solicitud
                                de inscripción al grado ${this.gradoEscolarSlNom} seleccionado.`;
                  this.cupoDisponible = false;
                }
                break;
              }
              case 'P': {
                const checkGrade = this.fvalidGrade(`${new Date().getFullYear()}-08-31`, this.fecha_nacimiento, 4);
                if (!checkGrade) {
                  this.msgModal = `El estudiante no cumple con la edad requerida para la solicitud
                                de inscripción al grado ${this.gradoEscolarSlNom} seleccionado.`;
                  this.cupoDisponible = false;
                }
                break;
              }
              case 'K': {
                const checkGrade = this.fvalidGrade(`${new Date().getFullYear()}-08-31`, this.fecha_nacimiento, 5);
                if (!checkGrade) {
                  this.msgModal = `El estudiante no cumple con la edad requerida para la solicitud
                                de inscripción al grado ${this.gradoEscolarSlNom} seleccionado.`;
                  this.cupoDisponible = false;
                }
                break;
              }
              default:
                this.cupoDisponible = true;
                this.msgModal = '';
            }
          }

          // console.log({ CUPO });
          // console.log({ CUPO_ADICIONAL });
          // console.log({ LISTA_ESPERA });
          // console.log({ OCUPADO });
          // console.log({ PROCESO });

          // console.log(gradoSelecionado);
          // console.log(value);
        } catch (error) {
          console.log(error);
        }
      }
    },
    '$i18n.locale': function lang(newVal) {
      this.getDataForm(newVal);
      return newVal;
      // console.log('locale change', newVal);
    },
    /* 'padre.empleado': function empleado(newVal) {
      if (!newVal) {
        this.padre.tipoId = '';
        this.padre.codColaborador = '';
        return;
      }
      // Seteo el tipo de id si es colaborador
      this.padre.tipoId = 3;
      this.padre.identificacion = ''; // Limpio el campo de identificacion
    }, */
    estudiante_vive(value) {
      this.inputData.VIVECON = value;
      this.set_estudiante_vive(value);
    },

  },
  methods: {
    ...mapMutations([
      'resp_solcupo',
      'setDataForm',
      'tutor_principal',
      'set_estudiante_vive',
    ]),
    ...mapActions(['solcupo_data_update']),
    save(date) {
      this.$refs.menu.save(date);
    },
    fvalidGrade(fechaTope, fechaNacimiento, edadMinima) {
      const today = new Date(fechaTope);
      // const today = new Date();
      // const birthDate = new Date(fechaNacimiento);

      const values = fechaNacimiento.split('-');
      const dia = values[2];
      const mes = values[1];
      const ano = values[0];

      // cogemos los valores actuales
      // eslint-disable-next-line camelcase
      const ahora_ano = today.getYear();
      // eslint-disable-next-line camelcase
      const ahora_mes = today.getMonth() + 1;
      // eslint-disable-next-line camelcase
      const ahora_dia = today.getDate();

      // realizamos el calculo
      // eslint-disable-next-line camelcase
      let edad = (ahora_ano + 1900) - ano;
      // eslint-disable-next-line camelcase
      if (ahora_mes < mes) {
        // eslint-disable-next-line no-plusplus
        edad--;
      }
      // eslint-disable-next-line camelcase, eqeqeq
      if ((mes == ahora_mes) && (ahora_dia < dia)) {
        // eslint-disable-next-line no-plusplus
        edad--;
      }
      if (edad > 1900) {
        edad -= 1900;
      }
      // Verifica si la edad está dentro del rango de edad permitido
      const result = edadMinima === edad;

      if (result) {
        this.cupoDisponible = true;
        this.msgModal = '';
      }

      return result;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split('/');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },
    handleValidateTutor(ref) {
      let vald = false;

      if (this.currentStepId === 1) {
        vald = this.$refs.form1.validate();
        this.$v.$touch();
        if ((vald && this.tutor === 'otros') || (vald
        && this.inputData.VIVECON === 'otros')) {
          vald = this.$refs.otrosfrm.$refs.frmTutor.validate();
        }
      }

      if (this.currentStepId !== 1) {
        vald = this.$refs[ref].$refs.frmTutor.validate();
      }

      // Datos del padre
      if (ref === 'padrefrm') {
        this.padre = this.$refs.padrefrm.tutor;
      } else if (ref === 'madrefrm') {
        this.madre = this.$refs.madrefrm.tutor;
      } else if (ref === 'otrasFrm') {
        this.tutorDatos = this.$refs.otrasFrm.tutor;
      }

      this.valid = vald;
      // vald = true;

      // console.log(vald);
      // debugger;
      if (vald) {
        if (ref === 'otrasFrm') this.guardarSolicitud();
        this.currentStepId = parseInt(this.currentStepId, 10) + 1;
      }
      // this.currentStepId = tutor === 'abspadres'? 4:3";
    },
    async guardarSolicitud() {
      this.loading = true;
      let otroTutor = '';
      // const fechaActual = new Date().toISOString().split('T')[0].replaceAll('-', '');

      if (this.tutor === 'otros' || this.inputData.VIVECON === 'otros') {
        otroTutor = this.$refs.otrosfrm.tutor;
      }

      /* ---------------------------- General del padre --------------------------- */
      let nombrePad = '';
      let idnumberPad = '';
      let nacionalidadPad = '';
      let telNumberPad = '';
      let mobNmberPad = '';
      let telOficinaPad = '';
      let emailPad = '';
      if (this.padre !== null) {
        nombrePad = `${this.$refs.padrefrm.tutor.nombre.toUpperCase()} ${this.$refs.padrefrm.tutor.apellidos.toUpperCase()}`;
        idnumberPad = this.$refs.padrefrm.tutor.identificacion;
        nacionalidadPad = this.$refs.padrefrm.tutor.nacionalidad.value;
        telNumberPad = this.$refs.padrefrm.tutor.casa_tel.replace(/[^0-9]+/g, '');
        mobNmberPad = this.$refs.padrefrm.tutor.celular.replace(/[^0-9]+/g, '');
        telOficinaPad = this.$refs.padrefrm.tutor.trabajo_tel.replace(/[^0-9]+/g, '');
        emailPad = this.$refs.padrefrm.tutor.email.toUpperCase();
      }
      /* ---------------------------- General del padre --------------------------- */

      /* ---------------------------- General del madre --------------------------- */
      let nombreMad = '';
      let idnumberMad = '';
      let nacionalidadMad = '';
      let telNumberMad = '';
      let mobNmberMad = '';
      let telOficinaMad = '';
      let emailMad = '';
      if (this.madre !== null) {
        nombreMad = `${this.$refs.madrefrm.tutor.nombre.toUpperCase()} ${this.$refs.madrefrm.tutor.apellidos.toUpperCase()}`;
        idnumberMad = this.$refs.madrefrm.tutor.identificacion;
        nacionalidadMad = this.$refs.madrefrm.tutor.nacionalidad.value;
        telNumberMad = this.$refs.madrefrm.tutor.casa_tel.replace(/[^0-9]+/g, '');
        mobNmberMad = this.$refs.madrefrm.tutor.celular.replace(/[^0-9]+/g, '');
        telOficinaMad = this.$refs.madrefrm.tutor.trabajo_tel.replace(/[^0-9]+/g, '');
        emailMad = this.$refs.madrefrm.tutor.email.toUpperCase();
      }
      /* ---------------------------- General del madre --------------------------- */

      /* ----------------------- Generales Estudiante start ----------------------- */
      const idEstudiante = this.$refs.estudianteFrm.estudiante.identificacion;
      const nombresEstud = this.$refs.estudianteFrm.estudiante.nombre.toUpperCase();
      const apellidosEstud = this.$refs.estudianteFrm.estudiante.apellidos.toUpperCase();
      const sexoEstud = this.$refs.estudianteFrm.estudiante.sexo.value;
      // const fechaNacEstud = this.$refs.estudianteFrm.estudiante.fecha_nacimiento;
      const fechaNacEstud = this.fecha_nacimiento;
      const nacionalidadEstud = this.$refs.estudianteFrm.estudiante.nacionalidad.value;
      const colegioProviene = this.$refs.estudianteFrm.inputColegios;
      const ultimoColegio = colegioProviene.length - 1;
      const {
        ZPCIS_RECN,
        ZNP_ESTU,
        ZPCIS_CS,
      } = this.$refs.estudianteFrm.estudiante; // estudiante files
      /* ------------------------ Generales Estudiante End ------------------------ */

      let solicitanteEs = '';
      if (this.tutor === 'madre' || this.tutor === 'madredda' || this.tutor === 'madredvc') {
        solicitanteEs = 'M';
      } else if (this.tutor === 'padre' || this.tutor === 'padreddo' || this.tutor === 'padredvc') {
        solicitanteEs = 'P';
      } else {
        solicitanteEs = 'O';
      }

      let pernr = '';
      let kunnr = '';
      let bpNumber = '';
      let telNumber = '';
      let mobNmber = '';
      let telOficina = '';
      let emailTutor = '';
      let poblacionTutor = '';
      let paisTutor = '';
      let nombres = '';
      let apellidos = '';
      let idnumber = '';
      let direccion = '';
      let estudianteEs = '';
      let comentario = '';

      if (Object.keys(this.getSolcupo).length > 3) {
        const {
          PERNR,
          KUNNR,
          TEL_NUMBER,
          BP_NUMBER,
          MOB_NMBER,
          TEL_OFICINA,
          EMAIL,
          COMENTARIO,
        } = this.getSolcupo;

        comentario = COMENTARIO;
        pernr = PERNR;
        kunnr = KUNNR;
        bpNumber = BP_NUMBER;
        telNumber = TEL_NUMBER;
        mobNmber = MOB_NMBER;
        telOficina = TEL_OFICINA;
        emailTutor = EMAIL;
        nombres = this.getSolcupo.NOMBRES;
        apellidos = this.getSolcupo.APELLIDOS;
        idnumber = this.getSolcupo.IDNUMBER;
        direccion = this.getSolcupo.DIRECCION;
        poblacionTutor = this.getSolcupo.POBLACION;
        paisTutor = this.getSolcupo.PAIS;
        estudianteEs = this.getSolcupo.ESTUDIANTE_ES;
      } else {
        /* --------------------------- Get tutor principal inicio -------------------------- */
        let refPrincipal = '';

        switch (this.tutor) {
          case 'madredvc':
          case 'madredda':
          case 'madre': {
            refPrincipal = 'madrefrm';
            break;
          }
          case 'padredvc':
          case 'padreddo':
          case 'padre': {
            refPrincipal = 'padrefrm';
            break;
          }
          case 'otros': {
            refPrincipal = 'otrosfrm';
            break;
          }
          default:
        }

        // const principal = JSON.parse(localStorage.getItem('tutor_principal'));
        // pernr = principal.PERNR;
        // kunnr = principal.KUNNR;
        // bpNumber = principal.BP_NUMBER;
        telNumber = this.$refs[refPrincipal].tutor.casa_tel;
        mobNmber = this.$refs[refPrincipal].tutor.celular;
        telOficina = this.$refs[refPrincipal].tutor.trabajo_tel;
        emailTutor = this.$refs[refPrincipal].tutor.email.toUpperCase();
        nombres = this.$refs[refPrincipal].tutor.nombre.toUpperCase();
        apellidos = this.$refs[refPrincipal].tutor.apellidos.toUpperCase();
        idnumber = this.$refs[refPrincipal].tutor.identificacion;
        direccion = `${this.$refs[refPrincipal].tutor.direccion.calle.toUpperCase()} ${this.$refs[refPrincipal].tutor.direccion.numero.toUpperCase()}`;
        poblacionTutor = (this.$refs[refPrincipal].tutor.direccion.poblacion.value) ? this.$refs[refPrincipal].tutor.direccion.poblacion.value : '';
        paisTutor = (this.$refs[refPrincipal].tutor.direccion.pais.value) ? this.$refs[refPrincipal].tutor.direccion.pais.value : '';
      }

      /* --------------------------- Get tutor principal fin -------------------------- */

      // console.log(this.$refs[principal.REF].tutor.nombre);
      //   console.log({ solicitanteEs });
      //   console.log(colegioProviene.length - 1);
      //   console.log(colegioProviene[ultimoColegio].colegio);

      //   console.log(this.$refs.estudianteFrm.inputColegios.pop());
      /* --------------------------- Get tutor principal -------------------------- */

      // Estructura a guardar de la solicitud
      let dataForm = JSON.stringify({
        fecha: this.fechaActual,
        centro: this.centro.toUpperCase(),
        nui: idEstudiante,
        IDSOLCUPO: (this.idsolcupo === 0) ? this.idsolcupo : this.getSolcupo.IDSOLCUPO,
        COMENTARIO: comentario,
        idinterlocutor: '',
        pernr,
        kunnr,
        bp_number: bpNumber,
        lifnr: '',
        nombres,
        apellidos,
        idnumber,
        direccion,
        poblacion: poblacionTutor.toUpperCase(),
        pais: paisTutor.toUpperCase(),
        tel_number: telNumber,
        mob_nmber: mobNmber,
        fax_number: '',
        tel_oficina: telOficina,
        email: emailTutor,
        idioma_corresp: '',
        solicitante_es: solicitanteEs,
        sol_otro_es: '',
        nombres_estud: nombresEstud.toUpperCase(),
        apellidos_estud: apellidosEstud.toUpperCase(),
        sexo_estud: sexoEstud,
        nacionalidad: nacionalidadEstud,
        fecha_nac_estud: fechaNacEstud,
        colegio_proviene: colegioProviene[ultimoColegio].colegio.toUpperCase(),
        pais_col_provien: colegioProviene[ultimoColegio].pais.value,
        anno_aplica: this.annioEscolarSl,
        grado_actual: '',
        grado_aplica: this.gradoEscolarSl,
        ensenanza_en: '',
        ensenanza_en_otr: '',
        estudiante_es: estudianteEs,
        fecha_evaluacion: '',
        hora_evaluacion: '',
        observacion: '',
        cumple_estandar: '',
        observacion_rech: '',
        status: 'SOLR',
        vbeln: '',
        idnumber_mad: idnumberMad,
        nombre_mad: nombreMad.toUpperCase(),
        nacionalidad_mad: nacionalidadMad,
        tel_number_mad: telNumberMad,
        mob_nmber_mad: mobNmberMad,
        tel_oficina_mad: telOficinaMad,
        email_mad: emailMad.toUpperCase(),
        idnumber_pad: idnumberPad,
        nombre_pad: nombrePad.toUpperCase(),
        nacionalidad_pad: nacionalidadPad,
        tel_number_pad: telNumberPad,
        mob_nmber_pad: mobNmberPad,
        tel_oficina_pad: telOficinaPad,
        email_pad: emailPad.toUpperCase(),
        json: {
          estudiante_vive: this.estudiante_vive,
          tutor: this.tutor,
          tutorOtro: this.tutorOtro,
          annioEscolarSl: this.annioEscolarSl,
          gradoEscolarSl: this.gradoEscolarSl,
          gradoEscolarSlNom: this.gradoEscolarSlNom,
          estudiante: {
            generales: this.$refs.estudianteFrm.estudiante,
            colegios: this.$refs.estudianteFrm.inputColegios,
            hermanos: this.$refs.estudianteFrm.inputHermanos,
            estu_vivecon: this.inputData.VIVECON.toUpperCase(),
            viveOptions: this.viveOptions,
          },
          padre: this.padre,
          madre: this.madre,
          informacionesMed: {
            infomedica: this.$refs.otrasFrm.infomedica,
            contactoEmergencia: this.$refs.otrasFrm.inputEmergencia,
          },
          infoDesarrollo: this.$refs.desarrolloFrm.infodesarrollo,
          otroTutor,
        },
        adjuntos: [
          {
            clase_documento: 'ZPCIS_RECN',
            nombre: 'Record-Nota',
            archivo: (ZPCIS_RECN) ? ZPCIS_RECN.fileBase64 : '',
            tipo_documento: (ZPCIS_RECN) ? ZPCIS_RECN.fileExt : '',
          },
          {
            clase_documento: 'ZPCIS_CPE',
            nombre: 'estudianteId',
            archivo: (ZNP_ESTU) ? ZNP_ESTU.fileBase64 : '',
            tipo_documento: (ZNP_ESTU) ? ZNP_ESTU.fileExt : '',
          },
          {
            clase_documento: 'ZPCIS_CS',
            nombre: 'CardaSaldo',
            archivo: (ZPCIS_CS) ? ZPCIS_CS.fileBase64 : '',
            tipo_documento: (ZPCIS_CS) ? ZPCIS_CS.fileExt : '',
          },
        //   {
        //     clase_documento: 'ZPCIS_CPP',
        //     nombre: 'padreID',
        //     archivo: this.$refs.padrefrm.archivoData,
        //     tipo_documento: this.$refs.padrefrm.archivoDataTipo,
        //   },
        //   {
        //     clase_documento: 'ZPCIS_CPM',
        //     nombre: 'madreId',
        //     archivo: this.$refs.madrefrm.archivoData,
        //     tipo_documento: this.$refs.madrefrm.archivoDataTipo,
        //   },
        //   {
        //     clase_documento: 'ZPCIS_FSC',
        //     nombre: 'frmSolcupo',
        //     archivo: this.$refs.padrefrm.archivoData,
        //     tipo_documento: this.$refs.padrefrm.archivoDataTipo,
        //   },
        ],
      });

      /* -------------------------------------------------------------------------- */
      /*                       Clase de Documentos de adjuntos start                */
      /* -------------------------------------------------------------------------- */
      /*
      ZPCIS_RECN PCIS - Record de nota
      ZPCIS_CPE PCIS - NUI/Pasaporte estudiante
      ZPCIS_CS PCIS - Carta de saldo
      ZPCIS_CPT Cédula/Pasaporte tutor
      ZPCIS_CPP Cédula/Pasaporte padre
      ZPCIS_CPM Cédula/Pasaporte madre
      ZPCIS_FSC PCIS - Formulario solicitud de cupo
      ZPCIS_FIM PCIS - Form. informacion médica */
      /* -------------------------------------------------------------------------- */
      /*                       Clase de Documentos de adjuntos end                  */
      /* -------------------------------------------------------------------------- */

      // console.log(this.$refs.padrefrm.tutor);
      // console.log(JSON.parse(dataForm));
      // return false;
      const dataFormParse = JSON.parse(dataForm);

      if (this.padre !== null) {
        dataFormParse.adjuntos.push(
          {
            clase_documento: 'ZPCIS_CPP',
            nombre: 'padreID',
            archivo: this.$refs.padrefrm.archivoData,
            tipo_documento: this.$refs.padrefrm.archivoDataTipo,
          },
        );
        // dataForm = dataFormParse;
      }

      if (this.madre !== null) {
        // const dataFormParse = JSON.parse(dataForm);
        dataFormParse.adjuntos.push(
          {
            clase_documento: 'ZPCIS_CPM',
            nombre: 'madreId',
            archivo: this.$refs.madrefrm.archivoData,
            tipo_documento: this.$refs.madrefrm.archivoDataTipo,
          },
        );
        // dataForm = dataFormParse;
      }

      if (this.tutor === 'otros' || this.inputData.VIVECON === 'otros') {
        // const dataFormParse = JSON.parse(dataForm);
        dataFormParse.adjuntos.push({
          clase_documento: 'ZPCIS_CPT',
          nombre: 'TutorId',
          archivo: this.$refs.otrosfrm.archivoData,
          tipo_documento: this.$refs.otrosfrm.archivoDataTipo,
        });
      }
      dataForm = dataFormParse;
      // console.log(dataFormParse);

      try {
        const { data } = await axios.post('api/formadmission/', dataForm);
        if (data.error) {
          this.msgModal = data.msg.replace('I::000 &', '').replace('&', '');
          this.loading = false;
          this.showModal = true;
          return false;
        }
        data.SOLCUPO_ENVIADA = dataForm;
        this.resp_solcupo(data);
        this.$router.push({ name: 'finish' });
      } catch (error) {
        console.log(error);
      }

      this.loading = false;
      return true;
    },
    async getDataForm(lang = 'es') {
      // Obtener los valores del formulario
      try {
        // const { data } = await axios.get(`api/formadmission?lang=${i18n.locale}`);

        const { data } = await axios.get(`api/formadmission?lang=${lang}`);
        // this.inputData = data;
        this.setDataForm(data);
      } catch (error) {
        console.log(error);
      }

      // Obtener los valores del formulario
      try {
        const { CENTRO_EDUCATIVO } = this.datosfrm;
        // Paises options
        const arrCentro = CENTRO_EDUCATIVO.map((centro) => (
          { text: centro.DESCRIPCION, value: centro.CENTRO }));
        this.centroOptions = arrCentro;
      } catch (error) {
        console.log(error);
      }

      /* -------------------------------------------------------------------------- */
      /*                           Info Estatus solicitud start                     */
      /* -------------------------------------------------------------------------- */
      /* LESP En lista de espera (MODIFICAR)
         SOLI Soliciud de información adicional (MODIFICAR)
         SOLR Solicitud recibida
         EXPR Examen programado
         FACT Evaluación facturada
         EXRE Examen realizado
         RECH Rechazado
         ADMI Admitido
         EXNR Examen no realizado
         CERR Cerrada */
      /* -------------------------------------------------------------------------- */
      /*                           Info Estatus solicitud end                       */
      /* -------------------------------------------------------------------------- */
    },
    goBack() {
      this.currentStepId -= 1;
      this.valid = true;
    },
    makeOptionsGrade(annioEscolar) {
      const key = 'GRADOESC';

      const grado = [...new Map(annioEscolar.map(
        (item) => [item[key], item],
      )).values()];

      grado.sort((a, b) => {
        const nameA = parseInt(a.GRADOESC, 10); // ignore upper and lowercase
        const nameB = parseInt(b.GRADOESC, 10); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });

      return grado.map((val) => ({
        text: val.DESCRIPCION, value: val.GRADOESC,
      }));
    },
  },
  created() {
    // this.getDataForm();
  },
  computed: {
    ...mapGetters({ datosfrm: 'getDataForm' }),
    ...mapGetters(['getSolcupo']),
    rulesPhone() {
      return () => (this.padre.celular.length > 0 || this.padre.casa_tel.length > 0) || this.$t('admission.rulesPhone');
    },
    lastStep() {
      return this.currentStepId.length;
    },
    currentStep() {
      return this.currentStepId[this.currentStepId - 1];
    },
    centroErrors() {
      const errors = [];
      if (!this.$v.centro.$dirty) return errors;
      if (!this.$v.centro.required) {
        errors.push(this.$t('El Centro es obligatorio.'));
      }
      return errors;
    },
    tutorOptions() {
      return [
        { text: this.$t('Madre'), value: 'madre' },
        { text: this.$t('Padre'), value: 'padre' },
        { text: this.$t('Madre Divorciada'), value: 'madredda' },
        { text: this.$t('Madre Soltera'), value: 'madredvc' },
        { text: this.$t('Madre Viuda'), value: 'madredvc' },
        { text: this.$t('Padre Divorciado'), value: 'padreddo' },
        { text: this.$t('Padre Soltero'), value: 'padredvc' },
        { text: this.$t('Padre Viudo'), value: 'padredvc' },
        { text: this.$t('Otros'), value: 'otros' },
      ];
    },
    viveOptions: {
      get() {
        return this.viveOptionsValues;
      },
      set(newValue) {
        this.viveOptionsValues = newValue;
      },
    },
  },
  async mounted() {
    this.getDataForm(this.$i18n.locale);
    const typeID = Number(this.idsolcupo) > 0 ? 'idsolcupo' : 'idhash';
    const id = this.idsolcupo;
    await this.solcupo_data_update({ typeID, id });

    if (Object.keys(this.getSolcupo).length > 3) {
      const {
        CENTRO,
        ANNO_APLICA,
        GRADO_APLICA,
      } = this.getSolcupo;

      const solcupo = JSON.parse(this.getSolcupo.JSON);

      const madreId = solcupo.adjuntos.filter((item) => item.clase_documento === 'ZPCIS_CPM').pop();
      const padreId = solcupo.adjuntos.filter((item) => item.clase_documento === 'ZPCIS_CPP').pop();

      this.centro = CENTRO;
      // await this.$nextTick();
      this.$nextTick(() => {
        this.annioEscolarSl = ANNO_APLICA;
      });

      this.$watch('annioEscolar', () => {
        this.gradoptions = this.makeOptionsGrade(this.annioEscolar);
      });

      await this.$nextTick(() => {
        this.gradoEscolarSl = GRADO_APLICA;
        // this.gradoEscolarSlNom = '( 5TH )';
      });
      // console.log(solcupo);
      this.tutor = 'madre';
      this.estudiante_vive = 'padres';

      await this.$nextTick(() => {
        this.currentStepId = this.tutor === 'padredvc' || this.tutor === 'madredvc' ? 5 : 6;
      });

      /* ----------------------------- form data padre start---------------------------- */
      this.$refs.padrefrm.tutor = solcupo.json.padre;
      this.$refs.padrefrm.archivoData = padreId.archivo;
      this.$refs.padrefrm.archivoDataTipo = padreId.tipo_documento;

      /* ----------------------------- form data padre end---------------------------- */

      /* ----------------------------- form data madre end---------------------------- */
      this.$refs.madrefrm.tutor = solcupo.json.madre;
      this.$refs.madrefrm.archivoData = madreId.archivo;
      this.$refs.madrefrm.archivoDataTipo = madreId.tipo_documento;

      /* ----------------------------- form data madre end---------------------------- */

      /* ----------------------------- form data estudiante end---------------------------- */
      this.$refs.estudianteFrm.estudiante = solcupo
        .json.estudiante.generales;
      this.$refs.estudianteFrm.inputColegios = solcupo
        .json.estudiante.colegios;
      this.$refs.estudianteFrm.inputHermanos = solcupo
        .json.estudiante.hermanos;
      /* this.$refs.estudianteFrm.estudiante.tipoId = solcupo
        .json.estudiante.generales.tipoId;
      this.$refs.estudianteFrm.estudiante.identificacion = solcupo
        .json.estudiante.generales.identificacion;
      this.$refs.estudianteFrm.estudiante.nombre = solcupo
        .json.estudiante.generales.nombre;
      this.$refs.estudianteFrm.estudiante.apellidos = solcupo
        .json.estudiante.generales.apellidos;
      this.$refs.estudianteFrm.estudiante.nacionalidad = solcupo
        .json.estudiante.generales.nacionalidad;
      this.$refs.estudianteFrm.estudiante.sexo = solcupo
        .json.estudiante.generales.sexo;
      this.$refs.estudianteFrm.estudiante.lugar_nacimiento = solcupo
        .json.estudiante.generales.lugar_nacimiento;
      this.$refs.estudianteFrm.estudiante.fecha_nacimiento = solcupo
        .json.estudiante.generales.fecha_nacimiento;
      this.$refs.estudianteFrm.estudiante.lengua_nativa = solcupo
        .json.estudiante.generales.lengua_nativa;
      this.$refs.estudianteFrm.estudiante.hermanos = solcupo
        .json.estudiante.generales.hermanos;
      this.$refs.estudianteFrm.estudiante.otro_idioma = solcupo
        .json.estudiante.generales.otro_idioma;
      this.$refs.estudianteFrm.inputColegios = solcupo
        .json.estudiante.colegios;
      this.$refs.estudianteFrm.inputHermanos = solcupo
        .json.estudiante.hermanos;
      this.$refs.estudianteFrm.estudiante.ZPCIS_RECN = solcupo
        .json.estudiante.generales.ZPCIS_RECN;
      this.$refs.estudianteFrm.estudiante.ZPCIS_CS = solcupo
        .json.estudiante.generales.ZPCIS_CS;
      this.$refs.estudianteFrm.estudiante.ZNP_ESTU = solcupo
        .json.estudiante.generales.ZNP_ESTU; */

      /* ----------------------------- form data estudiante end---------------------------- */

      /* --------------------- form informacion del desarrollo -------------------- */

      this.$refs.desarrolloFrm.infodesarrollo = solcupo
        .json.infoDesarrollo;

      /* --------------------- form informacion del desarrollo -------------------- */

      /* ----------------------------- form data medica end---------------------------- */
      this.$refs.otrasFrm.infomedica = solcupo
        .json.informacionesMed.infomedica;
      this.$refs.otrasFrm.inputEmergencia = solcupo
        .json.informacionesMed.contactoEmergencia;
      /* ----------------------------- form data medica end---------------------------- */
    }
  },
};
</script>

<style>

</style>
